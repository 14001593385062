<template>
  <div class="social text-white text-center pt-104 pb-62">
    <h1 data-test="social-title" class="text-42 leading-normal pb-16">{{ title }}</h1>
    <p class="px-71 text-20" v-html="text" />
    <social-items class="pt-80" :cards="children" theme="dark" />
  </div>
</template>

<script>
export default {
  name: 'SocialWelfare',
  components: {
    SocialItems: () => import('@/components/SocialItems'),
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
  },
}
</script>
